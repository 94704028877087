window.$ = require('jquery');

var NOT_INTERESTED_COOKIE_LIFETIME = 30;
var SIGNEDUP_COOKIE_LIFETIME = 365;

function getSignupConfig() {
    var $configDiv = $('.signup-modal-configs');
    var qualifiers = $configDiv.data('signupQualifiers');
    var signInModalURL = $configDiv.data('signupmodalurl');
    var config = {};

    if (typeof qualifiers === 'object' && qualifiers !== null) {
        config = qualifiers;
    } else if (typeof qualifiers === 'string') {
        try {
            config = JSON.parse(qualifiers);
        } catch (e) {
            console.warn('Invalid JSON in data-signup-qualifiers:', e);
        }
    }
    return { config, signInModalURL };
}

function isExcluded(config) {
    return config.exclusions?.excludedPages?.some(pagePath =>
        window.location.pathname.toLowerCase().includes(pagePath.toLowerCase())
    ) || false;
}

function setCookie(name, value, days) {
    var expires = days ? `; expires=${new Date(Date.now() + days * 864e5).toUTCString()}` : '';
    document.cookie = `${name}=${value}${expires}; path=/`;
}

function getCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
}

let modalFired = false; // Ensure modal is triggered only once

function triggerModal(signInModalURL, siteName) {
    if (modalFired) return;
    modalFired = true;

    $.ajax({
        url: signInModalURL,
        type: 'GET',
        success: function (response) {
            if (response && response.success == false) {
                return;
            }
            var $response = $(response.modalHTML);
            $('body').append($response);
            var $modal = $response.filter('#newsletter-modal').add($response.find('#newsletter-modal'));

            if (!$modal.length) return console.warn('Modal element not found.');
            
            $modal.css('display', 'flex');
            $("#thank-you-modal").hide();

            if (response.csrfToken) {
                var csrfInput = $('<input>', { type: 'hidden', name: 'csrf_token', value: response.csrfToken });
                $modal.find('form').append(csrfInput);
            }

            var cookieName = `${siteName}_hideSignupModal`;
            
            $modal.find('.close-popup, .not-interested-btn').on('click', function () {
                setCookie(cookieName, 'true', NOT_INTERESTED_COOKIE_LIFETIME);
                $modal.hide();
            });
            
            $modal.on('click', event => {
                if (event.target === event.currentTarget) $modal.hide();
            });

        },
        error: error => console.error('Error loading signup modal:', error)
    });
}

function checkTriggerConditions(config, signInModalURL, siteName) {
    const modalShown = sessionStorage.getItem('modalAlreadyShown') === 'true';

    if (sessionStorage.getItem('triggerModalNextPage') === 'true') {
        sessionStorage.removeItem('triggerModalNextPage');
        if (!modalShown) {
            triggerModal(signInModalURL, siteName);
            sessionStorage.setItem('modalAlreadyShown', 'true');
            return;
        }
    }

    var triggers = config.triggerConditions || {};

    function showModalOnce() {
        if (sessionStorage.getItem('modalAlreadyShown') === 'true') return;
        triggerModal(signInModalURL, siteName);
        sessionStorage.setItem('modalAlreadyShown', 'true');
    }

    if (triggers.onPageLoad && !modalShown) {
        showModalOnce();
    }

    if (triggers.afterXClicks) {
        let clickCount = parseInt(sessionStorage.getItem('modalClickCount') || '0', 10);

        const handleClick = (event) => {
            clickCount++;
            sessionStorage.setItem('modalClickCount', clickCount);
        
            if (clickCount >= triggers.afterXClicks && !modalShown) {
                $(document).off('click.modalTrigger');
        
                const clickedLink = event.target.closest('a');
        
                if (clickedLink && clickedLink.href) {
                    // Defer modal to next page
                    sessionStorage.setItem('triggerModalNextPage', 'true');
                } else {
                    // Show modal now
                    triggerModal(signInModalURL, siteName);
                    sessionStorage.setItem('modalAlreadyShown', 'true');
                }
        
                sessionStorage.removeItem('modalClickCount');
            }
        };

        $(document).on('click.modalTrigger', handleClick);
    }

    if (triggers.afterXSeconds && !modalShown) {
        setTimeout(() => {
            showModalOnce();
        }, triggers.afterXSeconds * 1000);
    }
}



function waitForAgeVerification() {
    var $html = $('html');

    function checkAgeGate() {
        if (!$html.hasClass('m-age_gate-open')) {
            initializeSignupModal();
            return true;
        }
        return false;
    }

    if (checkAgeGate()) return;

    const observer = new MutationObserver(() => {
        if (checkAgeGate()) {
            observer.disconnect();
        }
    });

    observer.observe($html[0], { attributes: true, attributeFilter: ['class'] });
}

function initializeSignupModal() {
    var { config, signInModalURL } = getSignupConfig();
    var siteName = config.siteName || 'defaultSite';
    var cookieName = `${siteName}_hideSignupModal`;
    var signedUpCookie = `${siteName}_userSignedUp`;

    SIGNEDUP_COOKIE_LIFETIME = config.signedUpCookie || 30;
    NOT_INTERESTED_COOKIE_LIFETIME = config.notInterestedCookie || 365;


    if (getCookie(cookieName) === 'true' ||
        getCookie(signedUpCookie)) return;

    if (!config || $.isEmptyObject(config) || isExcluded(config)) return;

    checkTriggerConditions(config, signInModalURL, siteName);
}

function validateEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function emailValidation() {
    $(document).on("input", "#newsletter-email", function () {
        var emailInput = $(this);
        var emailError = $("#email-error");
        var submitButton = $(".btn-signup");
        var errorMessage = $("#email-error-message").val();

        var isValid = validateEmail($.trim(emailInput.val()));

        if (!isValid) {
            emailError.text(errorMessage).addClass("error-visible");
        } else {
            emailError.text("").removeClass("error-visible");
        }

        submitButton.prop("disabled", !isValid);
    });
}

function newsletterSubmitForm() {
    $(document).on("submit", "#newsletter-form", function (e) {
        e.preventDefault();
        var email = $("#newsletter-email").val().trim();
        var form = $(this);
        var submitButton = $(".btn-signup");
        var loader = $(".loader-container");

        if (!validateEmail(email)) return;

        submitButton.prop("disabled", true); // Disable button to prevent multiple submissions
        loader.css("display", "flex");

        var formData = form.serialize();
        var siteName = getSignupConfig().config.siteName || 'defaultSite';
        var signedUpCookie = `${siteName}_userSignedUp`;

        $.ajax({
            url: $('.signup-modal-configs').data('signupmodalsubmit'),
            type: "POST",
            data: formData,
            dataType: "json",
            success: function (response) {
                loader.hide();
                submitButton.prop("disabled", false); // Re-enable button

                if (response.success) {
                    $("#newsletter-modal").hide();
                    $("#thank-you-modal").css("display", "flex");
                    setCookie(signedUpCookie, 'true', SIGNEDUP_COOKIE_LIFETIME);
                } else {
                    var errorMessage = $("#server-error-message").val();
                    if (errorMessage) {
                        $("#email-error").text(errorMessage).addClass("error-visible");
                    } else {
                        $("#email-error").text("Something went wrong. Please try again.").addClass("error-visible")
                    }
                }
            },
            error: function () {
                loader.hide();
                submitButton.prop("disabled", false);
            }
        });
    });
}

$(document).ready(function (){
    waitForAgeVerification();
    emailValidation();
    newsletterSubmitForm();

    // Close modals when clicking the close button (×)
    $(document).on("click", ".close-modal", function () {
        $(this).closest(".modal-overlay").hide();
    });

    // Close the thank-you modal when clicking "Continue Shopping"
    $(document).on("click", ".continue-shopping-btn", function () {
        $("#thank-you-modal").hide();
    });

    // Close modals when clicking outside (on overlay)
    $(document).on("click", ".modal-overlay", function (event) {
        if ($(event.target).is(".modal-overlay")) {
            $(this).hide();
        }
    });

});
